<template>
    <div>
        <el-table :data="displayData" style="width: 100%">
            <el-table-column prop="icon" :label="$t('Icon')" width="50">
                <template #default="scope">
                    <el-avatar :size="30" :src="scope.row.icon_path" />
                </template>
            </el-table-column>
            <el-table-column :label="$t('First Name')" prop="first_name" />
            <el-table-column :label="$t('Last Name')" prop="last_name" />
            <el-table-column :label="$t('Role')" prop="role" />
            <el-table-column :label="$t('Job Title')" prop="job_title" />
            <el-table-column prop="icon" :label="$t('Icon')" align="right">
                <template #header>
                    <el-input v-model="search" size="mini" :placeholder="$t('Search')" />
                </template>
                <template #default="scope">
                    <el-button type="primary" size="small" @click="triggerImpersonate(scope.row)">{{ $t('Impersonate') }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div>
            <CustomPagination
                class="mt-3"
                :current-page="page"
                :total="this.searchedData.length"
                :page-size="pageSize"
                :hide-on-single-page="true"
                @currentPageChange="handleCurrentChange"
                @pageSizeChange="handlePageSizeChange"
            />
        </div>
    </div>
</template>

<script>
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination";
export default {
    name: "SuperAdminUsersTable",
    components: { CustomPagination },
    props: {
        users: Array
    },
    emits: [
        'onImpersonate'
    ],
    data() {
        return {
            search: '',
            page: 1,
            pageSize: 5,
        }
    },
    computed: {
        searchedData() {
            if (this.search) {
                return this.users.filter(dt => dt.full_name.toLowerCase().includes(this.search));
            }
            return this.users;
        },
        displayData() {
            return this.searchedData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
        },
        handlePageSizeChange(size) {
            this.pageSize = size
        },
        triggerImpersonate(user) {
            this.$emit('onImpersonate', user);
        }
    }
};
</script>

<style scoped>

</style>
