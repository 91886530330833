import { BusinessModelMin } from "@/store/models/BusinessModelMin";
import { BankModelMin } from "@/store/models/bank/BankModelMin";
import { UserModelMin } from "@/store/models/shared/UserModelMin";
import { UserModel } from "@/store/models/UserModel";

export class SuperAdminUserManagementContainer {
    banksData: any;
    businessesData: any;

    constructor(obj: any) {
        this.banksData = obj.banks ? obj.banks.map(bk => {
            return {
                ...{},
                ...new BankModelMin(bk),
                users: bk.users ? bk.users.map(us => new UserModel(us)) : []
            };
        }) : [];

        this.businessesData = obj.businesses ? obj.businesses.map(bs => {
            return {
                ...{},
                ...new BusinessModelMin(bs),
                users: bs.users ? bs.users.map(us => new UserModel(us)) : []
            };
        }) : [];
    }
}
