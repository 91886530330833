<template>
    <CustomLoader v-if="pageLoading" />

    <div v-else class="super-admin-user-management">
        <div class="super-admin-dashboard">
            <div class="super-admin-dashboard__content user-management-content">
                <el-tabs type="border-card" class="demo-tabs">
                    <el-tab-pane :label="$t('Banks')">
                        <SuperAdminBankAndBusinessTable
                            :group-data="allUsersDataContainer.banksData"
                            @onImpersonate="handleImpersonate"
                        />
                    </el-tab-pane>
                    <el-tab-pane :label="$t('Businesses')">
                        <SuperAdminBankAndBusinessTable
                            :group-data="allUsersDataContainer.businessesData"
                            @onImpersonate="handleImpersonate"
                        />
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination";
import SuperAdminUsersTable from "@/buying-teams/pages/super-admin/user-management/components/SuperAdminUsersTable";
import SuperAdminBankAndBusinessTable
    from "@/buying-teams/pages/super-admin/user-management/components/SuperAdminBankAndBusinessTable";
import { diagLog } from "@/core/helpers/GlobalHelper";
import { SuperAdminUserManagementContainer } from "@/store/models/super-admin/SuperAdminUserManagementContainer";
import { DataService } from "@/core/services/DataService";
import InitialDataResolver from "@/core/services/InitialDataResolver";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { BusinessFilterService } from "@/core/services/BusinessFilterService";
import { BankFilterService } from "@/core/services/BankFilterService";

export default {
    name: "SuperAdminUserManagement",
    components: { SuperAdminBankAndBusinessTable, SuperAdminUsersTable, CustomPagination, CustomLoader },
    data() {
        return {
            pageLoading: true,
            allUsersDataContainer: null,
        }
    },
    mounted() {
        setCurrentPageBreadcrumbs({
            title: this.$t('User Management')
        });
        this.fetchAllUsers();
    },
    methods: {
        fetchAllUsers() {
            store.dispatch('getAllUsersList')
                .then(res => {
                    this.allUsersDataContainer = new SuperAdminUserManagementContainer(res);
                    diagLog('allUsersData => ', this.allUsersDataContainer);
                })
                .finally(() => {
                    this.pageLoading = false;
                })
        },
        async handleImpersonate(user) {
            this.pageLoading = true;
            let res = await store.dispatch('impersonate', user.id);

            if (res) {
                BusinessFilterService.removeAllFilters();
                BankFilterService.removeAllFilters();
                DataService.removeAllData();

                await InitialDataResolver.verifyAuthUser(
                    user.is_bank_user ?
                        PlatformTypeEnum.BANK :
                        PlatformTypeEnum.BUSINESS
                )

                setTimeout(() => {
                    window.location.href = '/';
                })
            }

            this.pageLoading = false;
        },

    }
};
</script>

<style lang="scss" scoped>
.super-admin-user-management {
    .el-tabs__item {
        line-height: unset !important;
    }
    .super-admin-dashboard {
        &__header {
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .dashboard-header {
                &__title {
                    display: flex;
                    align-items: center;
                    span {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 109%;
                        letter-spacing: -0.05em;
                        color: rgba(0, 0, 0, 0.8);
                        margin-right: 8px;
                    }
                    strong {
                        display: block;
                        background: #ECECEC;
                        border-radius: 3px;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        color: #000000;
                        padding: 3px 5px;
                        height: 22px;
                    }
                }
                &__text {
                    margin-top: 18px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    color: #9CA0AB;
                }
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }
}

</style>
