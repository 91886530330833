<template>
    <div>
        <el-table
            :data="displayData"
            style="width: 100%"
        >
            <el-table-column type="expand">
                <template #default="props">
                    <div class="users-table">
                        <SuperAdminUsersTable
                            :users="props.row.users"
                            @onImpersonate="triggerImpersonate"
                        />
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Icon')" width="100">
                <template #default="scope">
                    <el-avatar :size="40" :src="scope.row.icon_path" />
                </template>
            </el-table-column>
            <el-table-column :label="$t('Name')">
                <template #default="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>
            <el-table-column align="right">
                <template #header>
                    <el-input v-model="search" :placeholder="$t('Search')" />
                </template>
            </el-table-column>
        </el-table>
        <div class="text-right">
            <CustomPagination
                class="mt-3"
                :current-page="page"
                :total="searchedData.length"
                :page-size="pageSize"
                :hide-on-single-page="true"
                @currentPageChange="handleCurrentChange"
                @pageSizeChange="handlePageSizeChange"
            />
        </div>
    </div>
</template>

<script>
import SuperAdminUsersTable from "@/buying-teams/pages/super-admin/user-management/components/SuperAdminUsersTable";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination";
export default {
    name: "SuperAdminBankAndBusinessTable",
    components: { CustomPagination, SuperAdminUsersTable },
    props: {
        groupData: Array
    },
    emits: [
        'onImpersonate'
    ],
    data() {
        return {
            search: '',
            page: 1,
            pageSize: 5,
        }
    },
    computed: {
        searchedData() {
            if (this.search) {
                return this.groupData.filter(dt => dt.name && dt.name.toLowerCase().includes(this.search.toLowerCase()));
            }
            return this.groupData;
        },
        displayData() {
            return this.searchedData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
        },
        handlePageSizeChange(size) {
            this.pageSize = size
        },
        triggerImpersonate(user) {
            this.$emit('onImpersonate', user);
        }
    }
};
</script>

<style lang="scss" scoped>
.users-table {
    margin-left: 50px;
}
</style>
